import { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router";

import { AppContext } from "@plazarre/phoenix-ux-order/lib/esm/hook/use-application-context";
import { DoFetchRequest } from "@plazarre/phoenix.ux.fetch/lib/esm/hook/use-fetch-json";
import { toast } from "react-toastify";
import { FetchCall } from "@plazarre/phoenix.ux.fetch/lib/esm/core/domain/fetch-call";
import { Result } from "../api/result";
import { useProperty, useInvoice, WaveInvoice, ApprovalStep } from "@plazarre/phoenix.javascript.approval";

import { ApprovalActionStatusType, ApprovalDocument, ApprovalProcessInformation, ApprovalStepAction, ApprovalStepStatusType, InvoiceCommand, PropertyDisplayResult, useInvoiceProcess } from "@plazarre/phoenix.javascript.approval";

window.Buffer = window.Buffer || require("buffer").Buffer; 

interface InvoicePageData {
    propertyDisplayResult: PropertyDisplayResult,
    approvalUser: ApprovalStepAction,
    marketingOrderId: string,
    userSubjectId: string,
    /* waveInvoiceNumbers: number[], */
}

interface InvoiceDetails {
    invoice: number | null,
    md5: string | null,
}

interface InvoicePageParams {
    marketingOrderId: string,
    userSubjectId: string,
    invoices: InvoiceDetails[],
    noIdentity: string | null,
}

// Nice to have React shortcuts
export type ReactState<T> = React.Dispatch<React.SetStateAction<T>>;

export const useInvoicePage = () => {
    const [ fetchProperty, setFetchProperty ] = useState<FetchCall<InvoicePageData>>(FetchCall.Initialize);
    const [ invoices, setInvoices ] = useState<InvoiceDetails[]>([]);

    /** API calls */
    const { propertyDisplayMarketingOrder } = useProperty();
    const propertyDisplayMarketingFetch = propertyDisplayMarketingOrder[2];

    const { doGet: doGetInvoice } = useInvoice();
    const { invoiceProcessUpdate } = useInvoiceProcess();
    const [processUpdateResponse,, doProcessUpdate] = invoiceProcessUpdate;

    /** Context */
    const appContext = useContext(AppContext);
    
    const params = useParams();
    const {code} = params;

    const handleError = (errorMessage: string) => {
        console.error(errorMessage);
        setFetchProperty(FetchCall.Error(errorMessage));
    }

    /** Once the property information comes in from the fetch, go ahead and retrieve the approval information */
    const propertyFetch = useCallback(async (invoiceParams: InvoicePageParams) => {
        const { marketingOrderId, userSubjectId, invoices } = invoiceParams;
        const invoiceResponse = doGetInvoice({ urlParams: `${invoices[0].invoice}` });
        const propertyDisplayResponse = propertyDisplayMarketingFetch({ urlParams : marketingOrderId});

        // Get the secondary invoice, if applicable
        let invoice2Response: Promise<FetchCall<WaveInvoice>> | null = null;
        if (invoices.length > 1) {
            invoice2Response = doGetInvoice({ urlParams: `${invoices[1].invoice}` });
        }

        const [ invoiceResponseData, propertyDisplayResponseData, invoice2ResponseData ] = await Promise.all([invoiceResponse, propertyDisplayResponse, invoice2Response]);
        if (propertyDisplayResponseData.isError || !propertyDisplayResponseData?.data) 
            return handleError(propertyDisplayResponseData.errorMessage);

        if (invoiceResponseData.isError || !invoiceResponseData?.data) 
            return handleError(invoiceResponseData.errorMessage);

        if (invoice2ResponseData && (invoice2ResponseData.isError || !invoice2ResponseData?.data)) 
            return handleError(invoice2ResponseData.errorMessage);

        // Check to see if the invoice is already paid in Wave
        const invoice = invoiceResponseData.data;
        
        // Do we have the dates related to the last sent date? If so, check to see if already sent
        if (invoice.hasBeenSent) {
            return handleError(`Invoice #${invoice.invoiceNumber} has already been sent on ${invoice.lastSentAt}. No action required.`);    
        }

        if (invoice.hasBeenPaid) {
            return handleError(`Invoice #${invoice.invoiceNumber} has already been paid. No action required.`);
        }

        // Check to see if the invoice2 is already paid in Wave, if exists
        if (invoice2ResponseData?.data) {
            const invoice2 = invoice2ResponseData.data;
            if (invoice2.hasBeenSent) {
                return handleError(`Invoice #${invoice2.invoiceNumber} has already been sent on ${invoice2.lastSentAt}. No action required.`);    
            }
    
            if (invoice2.hasBeenPaid) {
                return handleError(`Invoice #${invoice2.invoiceNumber} has already been paid. No action required.`);
            }
        }

        const propertyDisplay = propertyDisplayResponseData.data;  
        let marketingIndex = propertyDisplay.orders?.map(a => a.id)?.findIndex(e => e === marketingOrderId) ?? -1;
        if (marketingIndex === -1)
            return handleError(`marketingIndex not found by marketingOrderId: ${marketingOrderId}`);

        let approvalProcessInformationJson = propertyDisplay.approvalProcessInformationJsonList[marketingIndex];
        if (!approvalProcessInformationJson) {
            // Sometimes the old marketing orders do not have approvals. In that case, search for the marketing id
            marketingIndex = propertyDisplay.approvalProcessInformationJsonList.findIndex(e => e.includes(marketingOrderId));
            approvalProcessInformationJson = propertyDisplay.approvalProcessInformationJsonList[marketingIndex];
            if (!approvalProcessInformationJson) {
                return handleError(`approvalProcessInformationJson not found by marketingOrderId: ${marketingOrderId}, index=${marketingIndex}`);
            }
        }

        const approvalJson = propertyDisplay.approvalProcessInformationJsonList[marketingIndex];
        let approvalProcessInformation: ApprovalProcessInformation;
        try {
            approvalProcessInformation = ApprovalProcessInformation.fromJSON(approvalJson);
        } catch (error) {
            return handleError(`Unable to JSON.parse approvalProcessInformationJson: ${approvalProcessInformationJson}`);
        }

        const approvalStepsAny = approvalProcessInformation.approvalSteps as any;
        const approvalSteps : ApprovalStep[] = approvalStepsAny?.$values != null ? approvalStepsAny.$values : approvalProcessInformation.approvalSteps;

        const currentStep = Object.values(approvalSteps)
                                  .find(e => e.status === ApprovalStepStatusType.Current);
                                  
        const approvalStepActionsAny = currentStep?.approvalStepActions as any;
        const approvalStepActions : ApprovalStepAction[] = approvalStepActionsAny?.$values != null ? approvalStepActionsAny.$values : currentStep?.approvalStepActions;
        const approvalUserFound = approvalStepActions.find(e => e.person.subjectId === userSubjectId) ?? null;
        if (!approvalUserFound) 
            return handleError(`approvalUserFound not found by subjectId: ${userSubjectId}`);

        if (approvalUserFound.person.subjectId !== appContext.userSubjectId && process.env.NODE_ENV.includes('production')) 
            return handleError(`You are not authorized to approve this invoice. The link is only authorized for ${approvalUserFound.person.name}.`);
        
        if (approvalUserFound.person.subjectId !== appContext.userSubjectId && !process.env.NODE_ENV.includes('production'))
            toast.warn(`approvalUserFound.subjectId !== appContext.userSubjectId: ${approvalUserFound.person.subjectId} !== ${appContext.userSubjectId}`); 

        setFetchProperty(FetchCall.Success({propertyDisplayResult: propertyDisplay, approvalUser: approvalUserFound, marketingOrderId, invoices, userSubjectId}));
    }, [appContext.userSubjectId, propertyDisplayMarketingFetch, doGetInvoice]);
    
    /** Once the incoming params come in, go ahead and retrieve the property information */
    useEffect(() => {
        const parseInvoiceDetails = (invoiceJson: string, invoice2Json: string) : Result<InvoiceDetails[]> => {
            const invoices : InvoiceDetails[] = [];
    
            try {
                invoices.push(JSON.parse(invoiceJson));
                if (invoice2Json) 
                    invoices.push(JSON.parse(invoice2Json));
    
                return Result.Success(invoices);
            } catch(exp) {
                return Result.Error('The invoice approval link is invalid.');
            }
        }

        if (!code || !appContext.isUserLoggedIn || invoices.length > 0) 
            return;

        const decoded = Buffer.from(code, 'base64').toString('ascii');
        const paramArray = decoded.split('|');
        const [urlVersion, userSubjectId, marketingOrderId, invoiceJson, invoice2Json] = paramArray;
        const noIdentity : string | null = paramArray.at(-1) ?? null;

        if (urlVersion !== 'v2')
            return handleError('The invoice approval link is no longer valid.');

        if (!userSubjectId) 
            return handleError('userSubjectId not found');

        if (!marketingOrderId) 
            return handleError('marketingOrderId not found');

        const invoicesResult = parseInvoiceDetails(invoiceJson, invoice2Json);
        if (!invoicesResult.isSuccess)
            return handleError(invoicesResult.errorMessage);
    
        if (!(invoicesResult.value ?? [])[0]?.invoice) 
            return handleError('waveInvoiceNumber not found');

        // Set to access during the call to accept/reject
        setInvoices(invoicesResult.value ?? []);

        // Once we have all the data, fetch it
        const invoiceParams : InvoicePageParams = { 
            invoices: invoicesResult.value ?? [], marketingOrderId, userSubjectId, noIdentity
        };
        
        propertyFetch(invoiceParams);
    }, [code, appContext.isUserLoggedIn, propertyFetch, invoices]);

    interface InvoiceFormOnSubmit {
        type: ApprovalActionStatusType, 
        comment: string
    };

    /** Submits the form given the ApprovalActionStatusType */
    const formOnSubmit = async (invoiceForm: InvoiceFormOnSubmit, e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!fetchProperty.data) {
            console.error(`fetchProperty.data is null`);
            toast.error('There was an error processing your request. Please try again.');
            return;
        }

        const {type, comment} = invoiceForm;
        const { userSubjectId, marketingOrderId } = fetchProperty.data;
        const approvalDocuments = invoices.map((e) => {
            const approvalDocument : ApprovalDocument = { name: `invoice-${e.invoice}`, MD5: e.md5 ?? '' };
            return approvalDocument;
        });

        const invoiceCommand : InvoiceCommand = {
            userSubjectId,
            actualSubjectId: appContext.userSubjectId,
            marketingOrderId,
            invoices: approvalDocuments,
            type,
            comment
        };

        const fetchInvoiceCommand : DoFetchRequest<InvoiceCommand> = { body : invoiceCommand };
        const invoiceResponse = await doProcessUpdate(fetchInvoiceCommand); 
        if (invoiceResponse.isError) {
            toast.error('There was an error processing your request. Please try again.');
            console.error('invoiceResponse', invoiceResponse);
            return;
        }
    }

    return { fetchProperty, formOnSubmit, processUpdateResponse };
}

