import React from "react";
import { Form } from "react-bootstrap";

import { ButtonSpinner } from "@plazarre/phoenix-ux-order/lib/esm/ux/atoms/button-spinner";
import { useInvoicePage } from "../../hooks/use-invoice-page";
import { ApprovalActionStatusType, WaveInvoice } from "@plazarre/phoenix.javascript.approval";


export interface InvoiceApprovePageProps {
}

// To test pass in MTA0OTc0NDAwNDk0MTg4MjQ0ODgyfGExZTRVMDAwMDBFNktoOFFBRnwyOTk5fEFwcHJvdmU=

export const InvoiceApprovePage : React.FC<InvoiceApprovePageProps> = (props) => {
    const { fetchProperty, formOnSubmit, processUpdateResponse } = useInvoicePage();
   
    if (fetchProperty.isLoading) {
        return(
            <>Please wait loading...</>
        );
    }

    if (fetchProperty.isError) {
        const errorDescription = fetchProperty.errorMessage ?? 'There was an error loading the invoice information. Please try again.';

        return(
            <>{errorDescription}</>
        );
    }

    // The error for this is handled in the useInvoicePage hook with a toast
    if (processUpdateResponse.hasData) {
        return(
            <>The invoice has been accepted.</>
        );
    }

    const property = fetchProperty.data?.propertyDisplayResult;
    if (!property) {
        return(
            <>There was an error loading the invoice information. Please try again.</>
        );
    }

    const status = fetchProperty.data?.approvalUser?.status;
    const isAlreadyRejected = status === ApprovalActionStatusType.Rejected;
    const isAlreadyAccepted = status === ApprovalActionStatusType.Complete;
    
    const mapInvoiceToJsx = (invoice: WaveInvoice, index: number) => {
        return (
            <span key={invoice.invoiceNumber}>
            Invoice # 
            {invoice.invoiceNumber}
            &nbsp;
            [<a href={invoice.pdfUrl} target="_blank" rel="noreferrer">View</a>]
            <br/>
            </span>
        )
    }

    return (
        <div>
            {property.fullStreetAddress}
            <br/>
            {property.invoices.map(mapInvoiceToJsx)}
            <br/>
            <br/>
            {isAlreadyAccepted && <span style={{ color: 'red' }}>You have already accepted this invoice. No action needed.<br/><br/></span>}
            {isAlreadyRejected && <span style={{ color: 'red' }}>You have already rejected this invoice. Click 'Approve' below will change its status to 'Accepted'.<br/><br/></span>}
            <br/>
            <Form onSubmit={(e) => {formOnSubmit({ type: ApprovalActionStatusType.Complete, comment: ''}, e )}}>
                <br/><br/>
                {!isAlreadyAccepted && <ButtonSpinner type="submit" variant="danger" waitingIcon={processUpdateResponse.isLoading}>Approve</ButtonSpinner>}
            </Form>
        </div>
        
    );
}

