import { ApprovalDocument, ApprovalProcessInformation, ApprovalStepAction, PropertyDisplayResult } from "@plazarre/phoenix.javascript.approval";


export interface IPropertyInvoiceDetailProps {
    selectedProperty: PropertyDisplayResult
}

export const PropertyInvoiceDetail: React.FC<IPropertyInvoiceDetailProps> = (props) => {
    const { selectedProperty } = props;
    
    const historyJsx : JSX.Element[] = [];

    // Build array --- one for each marketing order
    const approvalJsonList = selectedProperty?.approvalProcessInformationJsonList;
    const approvalProcessInformationList = approvalJsonList.map(e => ApprovalProcessInformation.fromJSON(e))
    const approvalDetails = approvalProcessInformationList.map(e => e.getApprovalDetail());
    approvalDetails.forEach((approvalDetail, index) => {
        const approvalStepActionsAny = approvalDetail.approvalStepAction as any;
        const approvalStepActions : ApprovalStepAction[] = approvalStepActionsAny?.$values != null ? approvalStepActionsAny.$values : approvalDetail.approvalStepAction;
        console.log('selectedApprovalStepActions', approvalDetail.approvalProcessInformation, approvalStepActions);
        approvalStepActions?.forEach((approvalStepAction, index) => {
            historyJsx.push(<><b>{approvalStepAction?.person?.name}:</b><br/></>);
            Object.entries(approvalStepAction?.statusHistory)?.forEach((statusHistory, index) => {
                var hasTypeInJson = statusHistory?.[0] === '$type';
                if (hasTypeInJson) {
                    return;
                }
                
                const statusDateTimeAny = hasTypeInJson ? (statusHistory?.[1] as any)?.statusDateTime : statusHistory?.[0];
                const statusDateTime = statusDateTimeAny ? new Date(Date.parse(statusHistory?.[0])) : null;
                const dateTimeString = `${statusDateTime?.toLocaleDateString()} ${statusDateTime?.toLocaleTimeString()}`;
                console.log('statusHistory', statusHistory);
                var documentsAny = statusHistory?.[1]?.documents as any;
                var documents : ApprovalDocument[] = documentsAny?.$values != null ? documentsAny.$values : documentsAny;
                let documentMD5String = '';
                if (!documents[0]?.MD5) {
                    const document = documents[0] as any;
                    documentMD5String = document?.mD5 ? document?.mD5.slice(-4) : '';
                } else {
                     documentMD5String = documents[0]?.MD5 ? `${statusHistory[1].documents[0].MD5.slice(-4)}` : '';
                }

                let statusString = statusHistory?.[1]?.status ?? '';
                if (documentMD5String) {
                    statusString += ` [${documentMD5String}]`;
                }

                historyJsx.push(<>&nbsp;&nbsp;&nbsp;&nbsp;{dateTimeString}: {statusString}<br/></>);
            });

            Object.entries(approvalStepAction.comments)?.forEach((comment, index) => {
                if (comment?.[0] === '$type') {
                    return;
                }
                
                const commentDateTime = comment?.[0] ? new Date(Date.parse(comment?.[0])) : null;
                const dateTimeString = `${commentDateTime?.toLocaleDateString()} ${commentDateTime?.toLocaleTimeString()}`;
                historyJsx.push(<>&nbsp;&nbsp;&nbsp;&nbsp;{dateTimeString}: {comment?.[1]}<br/></>);
            });
        });

        const approvalDocumentsAny = approvalDetail.approvalProcessInformation?.[0].approvalDocuments as any;
        const approvalDocuments : ApprovalDocument[] = approvalDocumentsAny?.$values != null ? approvalDocumentsAny.$values : approvalDocumentsAny;
        console.log('approvalProcessInformation', approvalDetail.approvalProcessInformation);

        approvalDocuments.forEach((document: any, index) => {
            historyJsx.push(<><br/><b>Current Document(s):</b><br/></>);
            const md5String = document.mD5 ? `/${document.mD5.slice(-4)}` : '';
            const documentString = `${document.name} (version ${document?.version}${(md5String)})`;
            historyJsx.push(<>{documentString}<br/></>);
        });

        const approvalPriorDocumentsAny = approvalDetail.approvalProcessInformation?.[0].priorDocuments as any;
        const approvalPriorDocuments : ApprovalDocument[] = approvalPriorDocumentsAny?.$values != null ? approvalPriorDocumentsAny.$values : approvalPriorDocumentsAny;
        if ((approvalPriorDocuments?.length ?? 0) > 0) {
            historyJsx.push(<><br/><b>Prior Document(s):</b><br/></>);
        }

        approvalPriorDocuments.forEach((document: any, index) => {
            const md5String = document.mD5 ? `/${document.mD5.slice(-4)}` : '';
            const documentString = `${document.name} (version ${document?.version}${(md5String)})`;
            historyJsx.push(<>{documentString}<br/></>);
        });
    });    

    return (
        <>
            Listing Id: {selectedProperty.listings?.[0]?.id}<br/>
            Document Owner: {approvalDetails[0]?.approvalPerson?.name}<br/>
            <b>Approval History:</b><br/>
            {historyJsx}
        </>
    );
}